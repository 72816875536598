@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Poppins", sans-serif;
  color: #0b1e33;
}

.text-blue {
  color: #00537a;
}

.w-80 {
  width: 80%;
}

.nav-item .nav-link {
  font-size: 20px;
  line-height: 30px;
  color: #0b1e33 !important;
  margin: 0 16px;
  padding: 0 !important;
}

.nav-item .nav-link.active {
  color: #00537a !important;
  font-weight: 700;
  border-bottom: 1px solid #00537a;
}

.btn {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 7px 13px !important;
}

.btn-signup {
  background: #ffba42 !important;
}

.sub-heading {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
}

.heading {
  font-weight: 700;
  font-size: 47px;
  line-height: 75px;
}

.sub-p {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
}

.start-btn {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  padding: 20px 27px !important;
}

.logo-heading {
  color: #929292;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
}

.logo_sec {
  padding-top: 70px;
}

.w-heading {
  font-weight: 700;
  font-size: 35px;
  line-height: 60px;
}

.w-sub-p {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.par {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.who_we_sec {
  padding-top: 100px;
}

.how_our_sec {
  padding-top: 100px;
}

.optimized_sec {
  padding-top: 100px;
}

.offer {
  margin-top: 50px;
}

.feature-form {
  border: 1px solid #00537a;
  box-shadow: 0px 5.78138px 28.9069px 4.81781px rgba(0, 0, 0, 0.12);
  border-radius: 6.00228px;
}

.feature-form label {
  font-weight: 700;
  font-size: 18.0068px;
  line-height: 27px;
  margin-bottom: 9px;
}

.feature-form button {
  font-size: 16px !important;
}

.home_slider_sec {
  padding-top: 60px;
}

.feature-form input,
.feature-form select,
.feature-form textarea {
  font-size: 12px;
  padding: 9.6px;
  border: 1px solid #ffba42;
}

.feature-form .form-control::placeholder {
  width: 80% !important;
  color: #cccccc;
  font-weight: 500;
}

.over-div {
  background: #00537a;
  max-width: 480px;
  max-height: 230px;
  border-radius: 10px;
  z-index: -1;
  width: 55%;
  height: 32%;
  top: -8%;
}

.offer {
  background: #f4f2f2;
}

.offer_row {
  padding: 30px 20px 30px;
}

.offer-heading {
  font-weight: 700;
  font-size: 23.3355px;
  line-height: 37px;
}

.offer-sub {
  font-weight: 400;
  font-size: 16.2237px;
  line-height: 24px;
}

.offer-icon {
  font-weight: 400;
  font-size: 12.9789px;
  line-height: 19px;
  color: #ffba42;
}

.testimonial_sec {
  margin-top: 100px;
}

.testimonial_card {
  background: #FFFFFF;
  box-shadow: 0px 3.3838px 3.3838px rgba(0, 0, 0, 0.25);
  border-radius: 7.81721px;
  padding: 20px 50px;
}

.card_text p {
  font-size: 15.6344px;
}

.card_text h5 {
  font-size: 23.4516px;
}

.card_text span {
  font-size: 15.6344px;

}

.pricing_plan {
  padding-top: 100px;
}

.plan_box_design {
  background: #FFFFFF;
  border: 1.95899px solid #00537A;
  padding: 40px 25px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plan_text h5 {
  font-weight: 700;
  font-size: 19.5899px;
}

.plan_text p {
  font-size: 13.0599px;
  line-height: 20px;
  color: #0b1e33;
}

.plan_text p.price_num {
  font-size: 19.5899px;
  line-height: 29px;
}

p.price_num span {
  font-weight: 700;
}

.plan_list {
  font-size: 13.0599px;
  line-height: 20px;
  font-weight: 700;
}

button.subscribe_btn {
  background: #FFBA42;
  width: 100%;
  font-weight: 500;
  font-size: 15.6719px;
  line-height: 13px;
  padding: 14px 0;
  border: 1px solid #FFBA42;
}

footer {
  margin-top: 100px;
  padding: 70px 0 40px;
  background: #00537A;
}

.list_heading h5 {
  font-weight: 700;
  font-size: 25px;
}

.list_heading ul li a {
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
}

.copyright_text {
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width:575px) {
  .home_slider_sec {
    padding-top: 30px;
  }

  .sub-heading {
    font-size: 18px;
    line-height: 10px;
  }

  .heading {
    font-size: 28px;
    line-height: 35px;
  }

  .sub-p {
    font-size: 16px;
    line-height: 25px;
  }

  .start-btn {
    font-size: 14px !important;
    padding: 4px 10px !important;
  }

  .order-text {
    order: 2;
  }

  .order-img {
    order: 1;
  }

  .logo_sec {
    padding-top: 50px;
  }

  .logo-heading {
    font-size: 18px;
    line-height: 22px;
  }

  .who_we_sec {
    padding-top: 50px;
  }

  .w-heading {
    font-size: 27px;
    line-height: 35px;
  }

  .w-sub-p {
    font-size: 16px;
    line-height: 22px;
  }

  .how_our_sec {
    padding-top: 50px;
  }

  .par {
    font-size: 16px;
    line-height: 25px;
  }

  .feature-form label {
    font-size: 16.0068px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .feature-form button {
    padding: 5px !important;
  }

  .optimized_sec {
    padding-top: 90px;
  }

  .offer_row {
    padding: 20px 10px 20px;
  }

  .offer-heading {
    font-size: 18.3355px;
    line-height: 25px;
  }

  .offer-sub {
    font-size: 14.2237px;
    line-height: 18px;
  }

  .testimonial_sec {
    margin-top: 50px;
  }

  .card_img img {
    width: 50%;
  }

  .card_text p {
    font-size: 14.6344px;
  }

  .testimonial_card {
    padding: 20px 15px;
  }

  .card_text h5 {
    font-size: 18.4516px;
  }

  .pricing_plan {
    padding-top: 50px;
  }

  .plan_box_design {
    padding: 25px 15px 25px;
    margin-bottom: 15px;
  }

  footer {
    margin-top: 50px;
    padding: 40px 0 25px;
  }

  .list_heading h5 {
    font-size: 18px;
  }

  .list_heading ul li a {
    font-size: 14px;
  }

  .footer_list {
    margin-bottom: 25px;
  }

  .copyright_text {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .home_slider_sec {
    padding-top: 50px;
  }

  .sub-heading {
    font-size: 20px;
    line-height: 20px;
  }

  .heading {
    font-size: 25px;
    line-height: 35px;
  }

  .sub-p {
    font-size: 18px;
    line-height: 25px;
  }

  .start-btn {
    font-size: 16px !important;
    padding: 7px 15px !important;
  }

  .logo-heading {
    font-size: 25px;
    line-height: 30px;
  }

  .who_we_sec {
    padding-top: 70px;
  }

  .w-heading {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
  }

  .w-sub-p {
    font-size: 16px;
    line-height: 25px;
  }

  .how_our_sec {
    padding-top: 70px;
  }

  .par {
    font-size: 16px;
    line-height: 25px;
  }

  .feature-form label {
    font-size: 17.0068px;
    line-height: 20px;
  }

  .optimized_sec {
    padding-top: 65px;
  }

  .offer-heading {
    font-size: 18.3355px;
    line-height: 15px;
  }

  .offer-sub {
    font-size: 15.2237px;
    line-height: 20px;
  }

  .offer-icon {
    font-size: 12.9789px;
    line-height: 25px;
  }

  .card_img img {
    width: 80%;
  }

  .testimonial_card {
    padding: 20px 20px;
  }

  .card_text h5 {
    font-size: 18.4516px;
  }

  .card_text span {
    font-size: 14.6344px;
  }

  .pricing_plan {
    padding-top: 60px;
  }

  .plan_box_design {
    padding: 25px 15px 25px;
  }

  footer {
    margin-top: 70px;
    padding: 50px 0 30px;
  }

  .list_heading h5 {
    font-weight: 600;
    font-size: 20px;
  }

  .list_heading ul li a {
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .btn {
    font-size: 16px !important;
    padding: 2px 12px !important;
  }

  a.navbar-brand {
    width: 22%;
  }
  .nav-item .nav-link {
    font-size: 17px;
    margin: 0px 12px;
}
}